// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-es-cgu-js": () => import("./../src/pages/es/cgu.js" /* webpackChunkName: "component---src-pages-es-cgu-js" */),
  "component---src-pages-es-js": () => import("./../src/pages/es.js" /* webpackChunkName: "component---src-pages-es-js" */),
  "component---src-pages-fr-cgu-js": () => import("./../src/pages/fr/cgu.js" /* webpackChunkName: "component---src-pages-fr-cgu-js" */),
  "component---src-pages-fr-js": () => import("./../src/pages/fr.js" /* webpackChunkName: "component---src-pages-fr-js" */),
  "component---src-pages-gtu-js": () => import("./../src/pages/gtu.js" /* webpackChunkName: "component---src-pages-gtu-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

